import React, { useEffect, useState } from "react";
import "../scss/content.scss"
import {ModailCost} from "../jsx/modail"
import Girl from "../img/map.png"
import Logo from "../img/logo.png"






export function Content(){
    const [iW,setIW] = useState(window.innerWidth)
    useEffect(()=>{
        window.onresize = ()=>{
            setIW(window.innerWidth)
        }
    })

    return(
        <div className="content">
            <img src={Logo} alt="" className={iW>850? "logo": "logo logo__mini"}/>
            <div className="content_midle">
                
                {
                    iW>850? <img className="img-screen" src={Girl} alt="img" />:""
                }
                <div className="case">
                    <span>THE FOUNDATION RAISES FOR COVERING THE COST OF FUEL FOR</span>
                    <ModailCost></ModailCost>
                    {
                        iW>850? "":<img className="img-screen" src={Girl} alt="img" />
                    }
                    <span>THE DELIVERY OF HUMANITARIAN AID</span>
                </div>
            </div>
        </div>
    )
}

export function Footer(){
    return(
        <footer className="footer">
            <div className="dop-info">
                <span className="dop-info_title"></span><br />
                <span className="dop-info_sub-title">Collected FUNDS will be sent to the Charitable Foundation "COMMUNITY DEVELOPMENT"</span><br />
                <span className="dop-info_info">How does the Foundation work?</span>
                <ul className="dop-info_list">
                    <li>We attract money from donors and EU countries, as well as in Ukraine with volunteers.</li>
                    <li>We buy fuel coupons in Ukraine, or covers costs of drivers, providing opportunities for caring people who help from any part of the world, and for Ukrainians to receive it throughout Ukraine.</li>
                    <li>We also buy the needed medical aid, and if it is impossible to get in Ukraine, we order abroad.</li>
                    <li>We cooperate with international charitable foundations Gift of the Givers, Spoetnik, and others. More details at the link: <a href="http://uhelp.org.ua">uhelp.org.ua</a></li>
                </ul>
            </div>
            <span className="footer_title">Raised funds will be used to help Ukrainian families affected by the war in Ukraine</span>
            <span className="footer_sub-title">©2022 , CO "CF Ukrainian Educational Foundation", Biedrība (BDR) "JK NAMS". All rights reserved.</span>
        </footer>
    )
}