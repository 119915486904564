import React from "react";

export class ButtonCost extends React.Component{

    
    render(){
        return(
            <a href={this.props.href}><button id={this.props.id} onClick={this.location}>{this.props.id} EUR</button></a>
        )
    }
}

