import React from 'react';
import ReactDOM from 'react-dom/client';
import { Content, Footer } from './jsx/contentor';

import "./scss/mane.scss"
import "./scss/media.scss"


const root = ReactDOM.createRoot(document.getElementById('body-website'));
root.render(
  <React.StrictMode>
    <Content></Content>
    <Footer></Footer>
  </React.StrictMode>
);
